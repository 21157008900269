import React from 'react';
import { Text } from 'wix-ui-tpa/cssVars';
import { TPA_EXPERIMENTS } from '@wix/pricing-plans-common/experiments';
import { Check } from '@wix/wix-ui-icons-common/on-stage/';
import { useExperiments } from '@wix/yoshi-flow-editor';
import { useBreakpoints } from '../../../../../hooks/useBreakpoints';
import { useLocale } from '../../../../../hooks/useLocale';
import { st, classes } from './StepSection.st.css';

export interface SectionProps {
  dataHook: string;
  position: number;
  title: string;
  state: 'pending' | 'active' | 'completed';
  action?: React.ReactNode;
}

export const StepSection: React.FC<SectionProps> = ({ dataHook, position, title, action, state, children }) => {
  const { locale } = useLocale();
  const { experiments } = useExperiments();
  const isNumberLocalizationEnabled = experiments.enabled(TPA_EXPERIMENTS.NUMBER_LOCALIZATION);
  const stepPosition = isNumberLocalizationEnabled ? Number(position).toLocaleString(locale) : position;

  const { isNarrow } = useBreakpoints();
  return (
    <div className={st(classes.root, { narrow: isNarrow, state })} data-hook={dataHook}>
      <header className={classes.header}>
        <Text className={classes.headerText} tagName="h2">
          <span>{state === 'completed' ? <Check /> : stepPosition}</span> {title}
        </Text>
        {action}
      </header>
      {children ? <div className={classes.content}>{children}</div> : null}
    </div>
  );
};
